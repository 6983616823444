// Redux
import { configureStore } from "@reduxjs/toolkit";
import web3Slice from "./web3-slice";
//

const store = configureStore({
  reducer: { web3: web3Slice.reducer },
});

export default store;
