import Tresor from "./particles/Tresor";

import { Image } from "react-bootstrap";

const Default = () => {
  return (
    <div>
         <Tresor/>
    </div>
  )
}

export default Default;
