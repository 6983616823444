import { Container, Image } from 'react-bootstrap';
import ShipQuestImg from '../../../assets/img/previewNFT.jpg';
import BlackPearl from '../../../assets/img/blackPearl.png';
import VagueImg from '../../../assets/img/vague.png';

const Quest = () => {
    return (
        <section className="page-section features text-white mb-0" id="quest"  >
            <Container>
                <h2 className="page-section-heading text-center text-uppercase text-white mb-0">Quest and NFT ships</h2>
                <div className="divider-custom">
                    <div className="divider-custom-line" ref={(el) => el && el.style.setProperty("background-color", "#ffffff" , "important")}></div>
                    <div className="divider-custom-icon" ref={(el) => el && el.style.setProperty("color", "#ffffff" , "important")}><i className="fas fa-ship"></i></div>
                    <div className="divider-custom-line" ref={(el) => el && el.style.setProperty("background-color", "#ffffff" , "important")}></div>
                </div>
            <Image id="shipart" src={ShipQuestImg} alt=""/>
            </Container>
            <Container style={{ marginTop: "30px"}}>
                <div className="row" style={{marginLeft: "auto !important", textAlign: "justify"}}>
                    <p className="lead">With Tortuga Coin, pirates will be able to buy their own NFT ship and to explore the ocean in Tortuga Quest Game. Rewards and NFT will be distributed to the best pirates !</p>
                    <p className="lead">All the ships will be exclusive and pirates will be able to submit their construction. NFT ships will be attached to one edition, for each edition we will change the theme and the design. With our Dapp, already owned and buyable NFT ships will be displayed in our animated island map. Once the Tortuga Quest is launched, you will explore the Ocean to get rewards. And maybe you will become a true legend of Tortuga Coin.</p>
                </div>
                  <div className="row">
                  <div className="col-lg-6 ml-auto">
                    <p className="lead">
                      <Image id="BlackPearl" src={BlackPearl} alt="" style={{marginLeft: "100px", width: "70%", marginTop: "20px"}}/>
                    </p>
                  </div>
                  <div className="col-lg-4 mr-auto">
                    <p className="lead text-center text-white" style={{ marginLeft: "auto !important", textAlign: "justify", marginTop: "50px"}}>
                      Maybe you will have the luck to sail on one of the Legendary Ships of Tortuga. One of them, the Black Pearl, can you spot Jack Sparrow on it ? 
                    </p>
                  </div>
                </div>
            </Container>
            <Image id="vague3" src={VagueImg} alt=""/>
        </section>
    )
}

export default Quest;
