// Redux
import { createSlice } from "@reduxjs/toolkit";
//

const initialState = { info: {} };

const web3Slice = createSlice({
  name: "web3",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.info = {
        ...action.payload,
      };
    },
  },
});

export const web3Actions = web3Slice.actions;
export default web3Slice;
