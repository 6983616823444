import { Image } from 'react-bootstrap';
import TortugaImg from '../../../assets/img/tortuga.png';

const Header = () => {
  return (
    <header className="masthead bg-primary text-blue text-center" id="header" style={{ marginTop: "-170px"}}>

        <div className="container d-flex align-items-center flex-column" >
            <p className="masthead-subheading font-weight-light mb-0" style={{marginTop: "-10px"}}>&#128034; You never interacted with Tortuga Contract ? &#128034;</p> <div style={{fontWeight: "bold", fontSize: "1.5em"}}> Connect to claim your airdrop !</div>
            <p className="masthead-subheading font-weight-light mb-0">&#127873; 5.000 airdrops available allowing you to pretend to the Treasure ! &#127873;</p>
            <Image style={{marginTop: "20px"}} className="masthead-avatar mb-5" src={TortugaImg} alt="..." />
            <h1 className="masthead-heading text-uppercase mb-0" style={{marginTop: "-25px"}}>Tortuga Coin</h1>
            <div className="divider-custom divider-light" style={{marginTop: "-5px"}}>
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-dharmachakra"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            <p className="masthead-subheading font-weight-light mb-0" style={{marginTop: "-25px"}}>Land on Tortuga Pirate island, share our treasure and get your NFT ship !</p>
            <a className="masthead-subheading font-weight-light mb-0" style={{wordBreak: "break-all", marginTop: "-10px", color: "#2c3d4f"}} href="https://cchain.explorer.avax.network/address/0xaB2712b217F0015B602C06E4fb66B8cf8B04F894/transactions">0xaB2712b217F0015B602C06E4fb66B8cf8B04F894</a>
        </div>
        
    </header>

  );
}

export default Header;
